<template>
  <div class="page">
    <div class="heading">
      <v-toolbar dense :flat="flatapp">
        <v-btn icon @click="menulink">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
        <v-breadcrumbs
          :items="crumbs"
          style="padding: 14px 20px"
          class="capitalize"
        >
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon color="primary" @click="refresh">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>Refresh</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" color="primary" icon @click="goBack">
              <v-icon>mdi-keyboard-return</v-icon>
            </v-btn>
          </template>
          <span>Go Back</span>
        </v-tooltip>
      </v-toolbar>
      <v-divider></v-divider>
    </div>

    <div class="body">
      <v-toolbar flat dense style="margin-bottom: 7px">
        <v-toolbar-title>
          {{ _decode(pat) | capitalize }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <div style="display: inline-block; padding-right: 16px">
          {{ myDate | myDate }},
        </div>
        {{ _decode(start) | myTime }}
        <span style="text-transform: lowercase; padding: 0px 7px">to</span>
        {{ _decode(end) | myTime }}
      </v-toolbar>

      <v-tabs v-model="tabs" centered>
        <v-tab key="more">More information</v-tab>
        <v-tab key="evidence">Evidence</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tabs">
        <v-tab-item key="more">
          <v-card flat>
            <v-card-text>
              <v-data-table
                :loading="loading"
                loader-height="1"
                :headers="headers"
                :items="moreList"
                :items-per-page="10"
              >
                <template v-slot:item.id="{ item }">
                  <v-btn
                    text
                    x-small
                    color="primary"
                    @click.stop="$set(viewDialog, item.id, true)"
                  >
                    view
                  </v-btn>
                  <MoreInfoView
                    v-if="viewDialog[item.id]"
                    :viewDialog="viewDialog[item.id]"
                    :myId="item.id"
                    @closeView="closeView"
                  />
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item key="evidence">
          <!-- start -->
          <v-data-iterator :items="evidenceList">
            <template v-slot:default="props">
              <v-row>
                <v-col
                  v-for="item in props.items"
                  :key="item.id"
                  cols="12"
                  sm="4"
                  md="4"
                  lg="4"
                >
                  <v-card flat outlined>
                    <v-img
                      height="350"
                      :src="pix(item.evidence)"
                      :title="item.description"
                    ></v-img>
                    <v-card-text class="card_list">
                      <ul>
                        <li>
                          <div class="myleft">information type</div>
                          <div class="myright">
                            {{ truncateString(item.information_typeName, 22) }}
                          </div>
                          <div class="clearall"></div>
                        </li>
                        <li>
                          <div class="myleft">description</div>
                          <div class="myright">
                            {{ truncateString(item.description, 25) }}
                          </div>
                          <div class="clearall"></div>
                        </li>
                        <li>
                          <div class="myleft">submitted</div>
                          <div class="myright">
                            {{ item.created | myDating }}
                          </div>
                          <div class="clearall"></div>
                        </li>
                      </ul>
                    </v-card-text>
                    <v-card-actions style="padding: 8px 16px">
                      <v-spacer></v-spacer>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-on="on"
                            icon
                            color="primary"
                            @click.stop="$set(viewDialog, item.id, true)"
                          >
                            <v-icon>mdi-eye</v-icon>
                          </v-btn>
                        </template>
                        <span>View Detail</span>
                      </v-tooltip>
                      <MoreInfoEvidence
                        v-if="viewDialog[item.id]"
                        :viewDialog="viewDialog[item.id]"
                        :myId="item.id"
                        :name="item.name"
                        @closeView="closeView"
                      />
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </template>
          </v-data-iterator>
          <!-- ends -->
        </v-tab-item>
      </v-tabs-items>
    </div>
    <v-snackbar
      :color="snack.color"
      v-model="snack.bar"
      :multi-line="snack.multiLine"
    >
      {{ snack.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snack.bar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Restful from "@/services/RestFul";
import Util from "../../mixins/utils";
import WinScroll from "../../mixins/windowscroll";
import MoreInfoView from "../../components/appointments/moreinfo/view.vue";
import MoreInfoEvidence from "../../components/appointments/evidence/view.vue";

export default {
  props: {
    id: { type: String },
    date: { type: String },
    loc: { type: String },
    start: { type: String },
    end: { type: String },
    pat: { type: String },
  },
  mixins: [Util, WinScroll("position")],
  components: { MoreInfoView, MoreInfoEvidence },
  data: () => ({
    addDialog: false,
    viewDialog: {},
    editDialog: {},
    removeDialog: {},
    tabs: null,
    crumbs: [
      {
        exact: true,
        link: true,
        text: "Dashboard",
        to: { name: "medics.dash" },
      },
      {
        exact: true,
        link: true,
        text: "Appointment",
        to: { name: "medics.appointment" },
      },
      {
        exact: true,
        link: true,
        text: "More Information",
        to: { name: "medics.appointment.more" },
      },
    ],
    snack: {
      color: "info",
      multiLine: true,
      bar: false,
      text: "Processing ... ",
    },
    loading: false,
    evidenceList: [],
    moreList: [],
    headers: [
      { text: "condition", value: "medics_request" },
      { text: "routine drugs", value: "routine_drugs" },
      { text: "allergies", value: "allergies" },
      { text: "active", value: "active", align: "center" },
      { text: "actions", value: "id", align: "center" },
    ],
  }),
  computed: {
    flatapp: function () {
      if (this.position[1] > 30) {
        return false;
      } else {
        return true;
      }
    },
    myDate: function () {
      let date = this._decode(this.date);
      return date;
    },
  },
  created() {
    this.detail();
    this.evidence();
    this.list();
  },
  methods: {
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
    evidence() {
      this.loading = true;
      let self = this;
      Restful.medics.evidence
        .list(this._decode(this.id))
        .then((response) => {
          console.log(response.data);
          if (response.status === 200) {
            this.evidenceList = response.data;
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
          self.loading = false;
        });
    },
    pix(img) {
      if (img === "") {
        return "";
      } else {
        return img;
      }
    },
    list() {
      this.loading = true;
      let self = this;
      Restful.medics.patientMoreInfo
        .list(this._decode(this.id))
        .then((response) => {
          console.log(response.data);
          if (response.status === 200) {
            this.moreList = response.data;
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
          self.loading = false;
        });
    },
    detail() {
      let date = this._decode(this.date);
      console.log(date);
    },
    refresh() {},
    menulink() {
      this.$emit("sidebar");
    },
    closeAdd(p) {
      this.addDialog = p.state;
    },
    submitAdd(p) {
      this.snack.bar = true;
      console.log(p);
      Restful.medics.patientMoreInfo
        .create(p.data)
        .then((response) => {
          console.log(response.data);
          this.snack.color = "success";
          this.snack.text = response.data;
          this.list();
        })
        .catch((error) => {
          console.log(error.response);
        });
      this.addDialog = p.state;
    },
    closeView(p) {
      this.$set(this.viewDialog, p.id, p.state);
    },
    closeEdit(p) {
      this.$set(this.editDialog, p.id, p.state);
    },
    submitEdit(p) {
      this.snack.bar = true;
      console.log(p);
      Restful.medics.patientMoreInfo
        .update(p.id, p.data)
        .then((response) => {
          console.log(response.data);
          this.snack.color = "success";
          this.snack.text = response.data;
          this.list();
        })
        .catch((error) => {
          console.log(error.response);
        });
      this.$set(this.editDialog, p.id, p.state);
    },
    closeRemove(p) {
      this.$set(this.removeDialog, p.id, p.state);
    },
    submitRemove(p) {
      this.snack.bar = true;
      console.log(p);
      Restful.medics.patientMoreInfo
        .delete(p.id)
        .then((response) => {
          console.log(response.data);
          this.snack.color = "error";
          this.snack.text = response.data;
          this.list();
        })
        .catch((error) => {
          console.log(error.response);
        });
      this.$set(this.removeDialog, p.id, p.state);
    },
  },
};
</script>
