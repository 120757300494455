<template>
  <v-dialog v-model="viewDialog" persistent max-width="700">
    <v-card :loading="loading" loader-height="2">
      <v-card-title>View Detail</v-card-title>
      <v-card-text>
        <div class="mylist">
          <ul>
            <li>
              <div class="myleft">specific medical condition</div>
              <div class="myright">{{ view.medics_request }}</div>
              <div class="clearall"></div>
            </li>
            <li>
              <div class="myleft">description</div>
              <div class="myright">{{ view.routine_drugs }}</div>
              <div class="clearall"></div>
            </li>
            <li>
              <div class="myleft">allergies</div>
              <div class="myright">{{ view.allergies }}</div>
              <div class="clearall"></div>
            </li>
            <li>
              <div class="myleft">active</div>
              <div class="myright">{{ view.active ? "Yes" : "No" }}</div>
              <div class="clearall"></div>
            </li>
            <li>
              <div class="myleft">created</div>
              <div class="myright">{{ view.created | myDating }}</div>
              <div class="clearall"></div>
            </li>
            <li>
              <div class="myleft">updated</div>
              <div class="myright">{{ view.updated | myDating }}</div>
              <div class="clearall"></div>
            </li>
          </ul>
        </div>
      </v-card-text>
      <v-card-actions>
        <span class="error_message">{{ responseMessage }}</span>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small text color="red" v-on="on" @click="closeDialog">
              NO
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Restful from "@/services/RestFul";
//   import moment from "moment";
//   import Utils from "./../../../../mixins/utils";
export default {
  props: {
    viewDialog: Boolean,
    myId: { type: Number, default: 0 },
    name: { type: String, default: null },
  },
  // mixins: [Utils],
  data() {
    return {
      responseMessage: null,
      loading: false,
      view: {},
    };
  },
  created() {
    this.find();
  },
  methods: {
    find() {
      this.loading = true;
      let self = this;
      Restful.medics.patientMoreInfo
        .find(this.myId)
        .then((response) => {
          console.log(response.data);
          this.view = response.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
          self.loading = false;
        });
    },
    closeDialog() {
      let payload = { id: this.myId, state: false };
      this.$emit("closeView", payload);
    },
  },
};
</script>
